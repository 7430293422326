<template>
	<div class="container">
		<lightBox ref="lightBox" :actIndex=actIndex :images=images :animation="true"/>

		<div class="cols2_2-8">
			<div v-if="imgs">
				<img v-for="img in imgs" :src="$store.state.server + '/' + img.path" :alt="img.alt_text" class="img1"  :title="img.title"  @click="showLighBoxMulti">
			</div>
			
			
			<div> <!-- right -->
				<div class="hl1">
					<h2 class="cap"> Mauern und Wege</h2>
				</div>

					<p class="cap" style="margin: 1.4rem 0">
						<span class="hgl1">Natursteinmauern</span> überwinden Höhen, lassen in Hanglagen ebene Flächen in Form von Terrassen entstehen und ersetzen überflüssige Böschungen. 
					</p>
					<p class="cap" style="margin: 1.4rem 0">
						Als wesentliches Mittel zur Raumgestaltung schaffen sie Nischen und versteckte Plätze und bieten Schutz vor Wind, Wetter und neugierigen Blicken. 
					</p>
					<p class="cap" style="margin: 2rem 0">
						Besonderen Reiz erhalten Steinmauern durch die Textur des Materials in Verbindung mit fachgerechter, liebevoller Verarbeitung.
					</p>
					<p class="cap" style="margin: 1.4rem 0">
						<span class="hgl1">Befestigte Flächen</span> machen den Garten betretbar und dienen in erster Linie der Benutzung durch den Menschen.
					</p>
					<p>
						Die <span class="hgl1">Pflasterung von Wegen und Sitzplätzen</span> schafft eine klare Trennung zu Rasen und Pflanzbeeten, bringt ordnende Strukturen und bestimmt die Verwendung der Gartenräume entscheidend mit.
						
					</p>
					<p class="cap">Die Wahl des Materials ist sowohl Geschmacks- als auch Kostenfrage.</p>
					<p><span class="hgl1" style="margin: 1.4rem 0">Natursteinpflasterungen</span>, wie zum Beispiel Porphyr oder Granit, wirken durch die Struktur des Materials lebendig und entfalten ihre eigene Dynamik. </p>
					<p class="cap">Doch auch der Betonstein hat sich in den letzten Jahren sehr weiterentwickelt und bietet oft preisgünstigere Lösungen mit durchaus natürlichem Erscheinungsbild.</p>

			</div>
		</div>
		



	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	import  lightBox from "@/components/LightBox.vue";

	export default {
		name: 'GG_Mauern_Wege',
		components: {
			// dialogComp,
			lightBox
		},
		mounted(){
				this.getImgs();


		}, // end mounted 
		data: 
		function(){
			return {
				images: [],
				actIndex: null,
				imgs: null,
			}
		}, // end data
		methods: {
						getImgs(){

				let self = this;
				let url = this.$store.state.server + '/api/getImagesIndex.php';

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { slug: 'gartengestaltung-mauern-wege' },
				})
				.then(function (resp) {

					self.imgs = resp.data.data[0];
					// self.pk = self.pkFilter = resp.data.data[1];

				});
			},

			showLighBoxMulti(e){

				this.images = []
				let coll = document.getElementsByClassName("container")[0].getElementsByTagName("img");

				let selSrc = e.target.src;

				let i = -1;
				    for (let item of coll) {
				    i++;

            if (item.src === selSrc) {
            	this.actIndex = i;
            }
            
				    this.images.push({src: item.src, height: item.naturalHeight, width: item.naturalWidth, title: item.title, alt: item.alt});
        }
			}


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">
	.container {
		height: 100%;
	}
	.img1 {
		margin-bottom: 2rem;
				cursor: pointer;
	}



</style>
